// VacancyCard.tsx
'use client';
import { Vacancy } from '@/types';
// import { useRouter as nextRouter } from 'next/router';
import Link from 'next/link';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FavoriteButton from '../FavoriteButton/FavoriteButton';
import TextCollapse from '../TextCollapse/TextCollapse';
import styles from './VacancyCard.module.scss';
import ImagesContainer from './imagesContainer/imagesContainer';

const convertToNormanDate = (passedDate: string) => {
  const date = new Date(passedDate?.toString());

  const day = date.getUTCDate();
  const month = new Intl.DateTimeFormat('ru-RU', { month: 'long' }).format(date);
  const year = date.getUTCFullYear();

  const formattedDate = `${day} ${month} ${year}`;

  return formattedDate;
};

interface VacancyCardProps {
  vacancy: Vacancy;
  onClick?: (vacancy: Vacancy) => void;
  isCurrentlyExpanded?: boolean;
}

const VacancyCard: React.FC<VacancyCardProps> = ({ vacancy, onClick, isCurrentlyExpanded }) => {
  const [isHovered, setIsHovered] = useState<any>();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const ammountOfImagesInLine = 3;

  const { t } = useTranslation();

  const { location_id, contactInfo, location, createdAt, id, information } = vacancy;

  return (
    <Link
      onClick={(e) => {
        console.log(`we click on the link`);
        e.stopPropagation();
        // e.preventDefault();
      }}
      href={{
        pathname: `/vacancy/${vacancy.id}`
        // currently we hide it cus we dont want to see all full url
        // query: { object: JSON.stringify(vacancy) }
      }}
      className={`${styles.vacancyCard} `}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      style={{
        backgroundColor: isHovered ? 'lightgreen' : 'white '
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '32px',
          marginBottom: '1rem',
          alignItems: 'center'
        }}
      >
        <div>
          <h3>
            {t('COMMON__LOCATION')}: {location ? location : ''}
          </h3>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }} className="">
          {!vacancy?.vip_until && (
            <strong style={{ paddingRight: '2vw' }}>
              {createdAt && convertToNormanDate(createdAt)}
            </strong>
          )}
          <div className="favButtonContainer">
            <FavoriteButton currentVacancy={vacancy} />
          </div>
        </div>
      </div>

      <span>
        <div style={{ width: '100%', wordWrap: 'break-word', color: 'black', height: '100%' }}>
          {vacancy.id && vacancy.contains_images ? (
            <ImagesContainer vacancyId={vacancy.id} />
          ) : (
            <></>
          )}
          <TextCollapse
            detectGA4VipClick={!!vacancy?.vip_until}
            vacancy={vacancy}
            isExpanded={isCurrentlyExpanded || isExpanded}
            setIsExpanded={setIsExpanded}
          />
        </div>
      </span>
    </Link>
  );
};

export default VacancyCard;
