import { Vacancy } from '@/types';
import { StarFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import styles from './FavoriteButton.module.scss';

interface FavoriteButtonProps {
  currentVacancy?: Vacancy;
  onClick?: (id: any) => void; // Define the type of onClick prop to accept an id
}

const FavoriteButton: React.FC<FavoriteButtonProps> = ({ currentVacancy, onClick }) => {
  const { t } = useTranslation();

  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  const handleStarClick = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      console.log(`we click on star ${currentVacancy}`);
      // Call the onClick function with the currentId if provided
      // onClick && onClick(currentVacancy);
      currentVacancy && addIdToLocalStorage(currentVacancy);
    },
    [currentVacancy]
  );

  // Function to add ID to localStorage and limit its size
  const addIdToLocalStorage = (vacancy: Vacancy) => {
    console.log(`addIdToLocalStorage called`);
    const maxIds = 5; // Change the maximum number of IDs allowed in localStorage
    const vacanciesFromLocal = localStorage.getItem('favoriteVacancies');

    let vacancies: Vacancy[] = [];

    if (vacancies && vacanciesFromLocal !== null) {
      vacancies = JSON.parse(vacanciesFromLocal);
    }

    // Check if the ID is already in the array
    const index = vacancies.findIndex((item) => item.id === vacancy.id);

    if (index !== -1) {
      // If the ID exists, remove it from the array
      vacancies.splice(index, 1);
      setIsFavorite(false);
    } else {
      // If the ID doesn't exist and the maximum limit is not reached, add it to the array
      if (vacancies.length < maxIds) {
        vacancies.push(vacancy);
        setIsFavorite(true);
      } else {
        // If the maximum limit is reached, display a notification
        alert(`Too many favorites!`);
      }
    }

    // Store the updated array back into localStorage
    localStorage.setItem('favoriteVacancies', JSON.stringify(vacancies));
  };

  useEffect(() => {
    // Retrieve favorite vacancies from local storage
    const favoriteVacanciesJson = localStorage.getItem('favoriteVacancies');

    if (favoriteVacanciesJson && currentVacancy) {
      // Parse the JSON data into an array of Vacancy objects
      const favoriteVacancies = JSON.parse(favoriteVacanciesJson) as Vacancy[];
      console.log(`favoriteVacancies`, favoriteVacancies);
      // Check if the vacancy object is present in the array
      const isFavorite = favoriteVacancies.some(
        (favVacancy) => favVacancy.id === currentVacancy.id
      );
      console.log(`isFavorite`, isFavorite);
      // Set state based on whether the vacancy is favorite or not
      setIsFavorite(isFavorite);
    }
  }, [handleStarClick]);

  return (
    <Tooltip title={isFavorite ? t(`REMOVE_FROM_FAVORITE`) : t(`ADD_FAVORITES`)}>
      <div
        className={`favButton ${isFavorite && `isFavorite`}`}
        style={{
          color: isFavorite ? 'rgb(255 237 0)' : 'rgb(255 237 0)',
          backgroundColor: isFavorite ? 'rebeccapurple' : ''
        }}
        onClick={handleStarClick}
      >
        <StarFilled />
      </div>
    </Tooltip>
  );
};

export default FavoriteButton;
